import React, { ReactElement, useContext } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import PrimaryGradient from 'components/PrimaryGradient';
import SectionContainer from 'components/SectionContainer';
import StyledButton from 'components/StyledButton';
import StyledText from 'components/StyledText';

import AuthContext from 'services/auth/AuthContext';

import COLORS from 'styles/colors';

function SettingsScreen(): ReactElement {
  const { signOut } = useContext(AuthContext);

  return (
    <SafeAreaView style={styles.page}>
      <PrimaryGradient />
      <View style={styles.mainContentContainer}>
        <PrimaryGradient />
        <SectionContainer title="Settings" />
        <StyledButton fullWidth title="Logout" onPress={signOut} />
      </View>
    </SafeAreaView>
  );
}

export default SettingsScreen;

const styles = StyleSheet.create({
  page: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  mainContentContainer: {
    backgroundColor: COLORS.BACKGROUND,
    paddingTop: 15,
    paddingHorizontal: 24,
    minHeight: '100%',
    height: '100%',
    width: '100%',
  },
  sectionHeader: {
    color: COLORS.PRIMARY,
    fontSize: 24,
    fontWeight: '700',
    marginTop: 22,
    marginBottom: 16,
  },
});
