import MaterialIcon from '@expo/vector-icons/MaterialIcons';
import {
  BottomTabBarProps,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import { NavigationProp } from '@react-navigation/native';
import React, { ReactElement, useMemo } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';

import StyledText from 'components/StyledText';

import COLORS from 'styles/colors';

import { RootStackPropList } from '../../../App';
import CollectionNavigation from './collection/CollectionNavigation';
import ProfileScreen from './profile/ProfileScreen';
import ScanNavigation from './scan/ScanNavigation';
import SettingsScreen from './settings/SettingsScreen';

export type MainTabPropList = {
  ProfileScreen: undefined;
  CollectionNavigation: undefined;
  SettingsNavigation: undefined;
  ScanNavigation: undefined;
};

const Tab = createBottomTabNavigator<MainTabPropList>();

function MainNavigation(): ReactElement {
  return (
    <Tab.Navigator
      tabBar={(props) => <CustomTabBar {...props} />}
      screenOptions={{ headerShown: false }}
      initialRouteName="ProfileScreen"
    >
      <Tab.Screen name="ProfileScreen" component={ProfileScreen} />
      <Tab.Screen
        name="CollectionNavigation"
        component={CollectionNavigation}
      />
      <Tab.Screen name="SettingsNavigation" component={SettingsScreen} />
    </Tab.Navigator>
  );
}

export default MainNavigation;

function CustomTabBar(props: BottomTabBarProps): ReactElement {
  const { state } = props;
  const navigation = props.navigation as unknown as NavigationProp<
    MainTabPropList & RootStackPropList
  >;

  const currentRouteName = useMemo(() => {
    return state.routeNames[state.index];
  }, [state]);

  return (
    <View style={styles.tabBarContainer}>
      <View style={styles.tabBarElement}>
        <Pressable
          style={styles.tabBarButton}
          onPress={() => navigation.navigate('ProfileScreen')}
        >
          <MaterialIcon
            name="badge"
            size={24}
            style={
              currentRouteName === 'ProfileScreen'
                ? styles.activeTabIcon
                : styles.inActiveTabIcon
            }
          />
          <StyledText
            style={[
              styles.tabButtonText,
              currentRouteName === 'ProfileScreen'
                ? styles.activeTabIcon
                : styles.inActiveTabIcon,
            ]}
          >
            Card
          </StyledText>
        </Pressable>
      </View>

      <View style={styles.tabBarElement}>
        <Pressable
          style={styles.tabBarButton}
          onPress={() => navigation.navigate('ScanNavigation')}
        >
          <MaterialIcon
            name="qr-code-scanner"
            size={24}
            style={
              currentRouteName === 'ScanNavigation'
                ? styles.activeTabIcon
                : styles.inActiveTabIcon
            }
          />
          <StyledText
            style={[
              styles.tabButtonText,
              currentRouteName === 'ScanNavigation'
                ? styles.activeTabIcon
                : styles.inActiveTabIcon,
            ]}
          >
            Scan
          </StyledText>
        </Pressable>
      </View>

      <View style={styles.tabBarElement}>
        <Pressable
          style={styles.tabBarButton}
          onPress={() => navigation.navigate('CollectionNavigation')}
        >
          <MaterialIcon
            name="recent-actors"
            size={24}
            style={
              currentRouteName === 'CollectionNavigation'
                ? styles.activeTabIcon
                : styles.inActiveTabIcon
            }
          />
          <StyledText
            style={[
              styles.tabButtonText,
              currentRouteName === 'CollectionNavigation'
                ? styles.activeTabIcon
                : styles.inActiveTabIcon,
            ]}
          >
            Collection
          </StyledText>
        </Pressable>
      </View>

      <View style={styles.tabBarElement}>
        <Pressable
          style={styles.tabBarButton}
          onPress={() => navigation.navigate('SettingsNavigation')}
        >
          <MaterialIcon
            name="settings"
            size={24}
            style={
              currentRouteName === 'SettingsNavigation'
                ? styles.activeTabIcon
                : styles.inActiveTabIcon
            }
          />
          <StyledText
            style={[
              styles.tabButtonText,
              currentRouteName === 'SettingsNavigation'
                ? styles.activeTabIcon
                : styles.inActiveTabIcon,
            ]}
          >
            Settings
          </StyledText>
        </Pressable>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  tabBarContainer: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    bottom: 0,
    left: 0,
    right: 0,
    height: 60,
    shadowColor: '#000000',
    shadowOpacity: 0.3,
    shadowRadius: 1,
  },
  tabBarElement: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    maxHeight: '100%',
  },
  tabBarButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 15,
    paddingRight: 15,
  },
  scanButton: {
    borderRadius: 40,
    backgroundColor: COLORS.PRIMARY,
    height: 80,
    width: 80,
    top: -30,
    borderWidth: 2,
    borderColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2,
  },
  scanButtonIcon: {
    color: 'white',
  },
  inActiveTabIcon: {
    color: COLORS.DARK_GRAY,
  },
  activeTabIcon: {
    color: COLORS.PRIMARY,
  },
  tabButtonText: {
    marginTop: 6,
  },
});
