import {
  NavigationContainer,
  createNavigationContainerRef,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import AppLoading from 'expo-app-loading';
import { useFonts } from 'expo-font';
import { StatusBar } from 'expo-status-bar';
import { ReactElement, useContext, useEffect, useMemo } from 'react';

import AuthLoadingScreen from 'screens/auth/AuthLoadingScreen';
import LoginScreen from 'screens/login/LoginScreen';
import MainNavigation from 'screens/main/MainNavigation';
import CollectionScreen from 'screens/main/collection/CollectionScreen';
import ScanNavigation from 'screens/main/scan/ScanNavigation';
import ScanScreen from 'screens/main/scan/ScanScreen';
import IntroScreen from 'screens/onboarding/intro/IntroScreen';

import AuthContext, { AuthContextProvider } from 'services/auth/AuthContext';
import LaunchContext, {
  LaunchContextProvider,
} from 'services/launch/LaunchContext';

function App() {
  const [fontLoaded] = useFonts({
    ProximaNova: require('assets/fonts/proximanova-regular.otf'),
  });

  const appLoading = useMemo(() => {
    return !fontLoaded;
  }, [fontLoaded]);

  if (appLoading) {
    return <AppLoading />;
  }

  return (
    <LaunchContextProvider>
      <AuthContextProvider>
        <StatusBar style="auto" />
        <RootNavigation />
      </AuthContextProvider>
    </LaunchContextProvider>
  );
}

export default App;

export type RootStackPropList = {
  LoadingScreen: undefined;
  LoginScreen: undefined;
  IntroScreen: undefined;
  MainNavigation: undefined;
  ScanModal: undefined;
  CollectionScreen: undefined;
  ScanNavigation: undefined;
};

const navigationRef = createNavigationContainerRef<RootStackPropList>();
const Stack = createStackNavigator();

function RootNavigation(): ReactElement {
  const { state: authState } = useContext(AuthContext);
  const { isFirstLaunch } = useContext(LaunchContext);

  useEffect(() => {
    if (!navigationRef.isReady()) {
      return;
    }

    if (authState.isLoading) {
      navigationRef.navigate('LoadingScreen');
      return;
    }

    if (!authState.accessToken) {
      navigationRef.navigate('LoginScreen');
      return;
    }

    if (isFirstLaunch) {
      navigationRef.navigate('IntroScreen');
      return;
    }

    navigationRef.navigate('MainNavigation');
  }, [authState, isFirstLaunch]);

  return (
    <NavigationContainer ref={navigationRef}>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
          animationEnabled: true,
        }}
      >
        <Stack.Group>
          <Stack.Screen name="LoadingScreen" component={AuthLoadingScreen} />
          <Stack.Screen name="LoginScreen" component={LoginScreen} />
          <Stack.Screen name="IntroScreen" component={IntroScreen} />
          <Stack.Screen name="CollectionScreen" component={CollectionScreen} />
          <Stack.Screen name="ScanNavigation" component={ScanNavigation} />
          {authState.accessToken && (
            // We need to wait for the auth token to be available before rendering the view
            // as it will already try to do API calls before it is even navigated to
            <Stack.Screen name="MainNavigation" component={MainNavigation} />
          )}
        </Stack.Group>
      </Stack.Navigator>
    </NavigationContainer>
  );
}
